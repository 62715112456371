<template>
  <!-- Start Newsletter -->
  <section class="newsletter">
    <div class="container">
      <div class="newsletter-inner">
        <div class="row">
          <div class="col-lg-5">
            <h2>Subscribe to our newsletter</h2>
            <p>Get started with Pranayama, Start working together Easily</p>
          </div>
          <div class="col-lg-7">
            <form class="newsletter-form" action="#" method="post">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="Enter your email"
                  required
                />
                <button class="main-btn" type="submit" name="send">
                  <span>Subscribe</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  End Newsletter -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>